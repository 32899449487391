<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <template v-if="page === 0">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">
                <div @click="$emit('close')" class="icon">
                  <ModalCloseIcon :dark="true" />
                </div>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">
                <div class="logo">
                  <img src="../../assets/logo.png" alt="Nvoko Logo" />
                </div>
                <h2>Welcome to Nvoko</h2>
                <h5>
                  Get features, production, and feedback on your music from the
                  pros you love, and use our smart forms to get awesome
                  contracts that cover copyrights, royalties, and more.
                </h5>
                <button @click="goToNextPage()" class="primary">
                  Get Started
                </button>
              </slot>
            </div>
          </div>
        </template>
        <template v-if="page > 0">
          <div class="modal-container-2">
            <div class="modal-header-2">
              <slot name="header">
                <div class="logo">
                  <img src="../../assets/logo.png" alt="Nvoko Logo" />
                </div>
                <div @click="$emit('close')" class="icon">
                  <ModalCloseIcon :dark="true" />
                </div>
                <div class="progressbar">
                  <div
                    class="line"
                    :style="{ width: progressPercent + '%' }"
                  ></div>
                </div>
              </slot>
            </div>

            <div class="modal-body-2">
              <slot name="body">
                <div v-if="page === 1" class="step-1">
                  <div class="inputs">
                    <div class="form-group dark">
                      <label for="pro" class="label"
                        >Which PRO are you affiliated with?</label
                      >
                      <input
                        id="pro"
                        type="text"
                        class="text-input"
                        placeholder="BMI, ASCAP, SOCAN..."
                      />
                    </div>
                    <div class="form-group dark">
                      <label for="sipi" class="label"
                        >What’s your songwriter IPI number?</label
                      >
                      <input
                        id="sipi"
                        type="text"
                        class="text-input"
                        placeholder="Type-in number"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="page === 2" class="step-2">
                  <h3>Invite your team</h3>
                  <div class="inputs">
                    <div class="form-group dark">
                      <input
                        id="pro"
                        type="text"
                        class="text-input"
                        placeholder="Email"
                      />
                    </div>
                    <div class="form-group dark">
                      <CustomSelect
                        :options="[
                          { value: 'ff', label: 'Features' },
                          { value: 'ff', label: 'Feedback' }
                        ]"
                        default="Genre"
                      />
                    </div>
                    <div class="form-group dark">
                      <CustomSelect
                        :options="[
                          { value: 'ff', label: 'Features' },
                          { value: 'ff', label: 'Feedback' }
                        ]"
                        default="Genre"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="page === 3" class="step-3">
                  <div class="group">
                    <h5 class="title">
                      I'm interested in providing features for:
                    </h5>
                    <div class="radiobuttons dark">
                      <div class="radio-group">
                        <label class="radio">
                          <input
                            type="radio"
                            id="providing1"
                            name="providing"
                            value="royalties"
                          />
                          <span class="text">$</span>
                          <span class="text">Flat Fee</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="radio-group">
                        <label class="radio">
                          <input
                            type="radio"
                            id="providing2"
                            name="providing"
                            value="royalties"
                          />
                          <span class="text">$ + %</span>
                          <span class="text">Fee + Royalties</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="radio-group">
                        <label class="radio">
                          <input
                            type="radio"
                            id="providing3"
                            name="providing"
                            value="royalties"
                          />
                          <span class="text">%</span>
                          <span class="text">Only Royalties</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="group">
                    <div class="inline">
                      <span class="text">I usually want</span>
                      <div class="form-group dark">
                        <input
                          id="featurefrom"
                          type="text"
                          class="text-input"
                          value="$100"
                        />
                      </div>
                      <span class="text">to</span>
                      <div class="form-group dark">
                        <input
                          id="featureto"
                          type="text"
                          class="text-input"
                          value="$200"
                        />
                      </div>
                      <span class="text">for a feature</span>
                    </div>
                  </div>
                </div>
                <div v-if="page === 4" class="step-4">
                  <div class="group">
                    <h5 class="title">
                      I’m interested in collaborating with artists who have:
                    </h5>
                    <div class="radios dark">
                      <div class="radio-group">
                        <label class="radio">
                          Any amount of monthly listeners
                          <input
                            type="radio"
                            id="collaborate1"
                            name="collaborate"
                            value="any"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="radio-group">
                        <label class="radio">
                          At least 10,000 monthly listeners
                          <input
                            type="radio"
                            id="collaborate2"
                            name="collaborate"
                            value="10000"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="radio-group">
                        <label class="radio">
                          At least 100,000 monthly listeners
                          <input
                            type="radio"
                            id="collaborate3"
                            name="collaborate"
                            value="100000"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="radio-group">
                        <label class="radio">
                          At least 250,000 monthly listeners
                          <input
                            type="radio"
                            id="collaborate4"
                            name="collaborate"
                            value="250000"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="radio-group">
                        <label class="radio">
                          At least 500,000 monthly listeners
                          <input
                            type="radio"
                            id="collaborate5"
                            name="collaborate"
                            value="500000"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="radio-group">
                        <label class="radio">
                          At least 1,000,000 monthly listeners
                          <input
                            type="radio"
                            id="collaborate6"
                            name="collaborate"
                            value="1000000"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="page === 5" class="step-5">
                  <div class="group">
                    <h5 class="title">Do you have any stylistic standards?</h5>
                    <div
                      contenteditable
                      class="textarea"
                      name="standards"
                      id="standards"
                    >
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nulla enim in cras id enim elementum quam scelerisque.
                      Magna id ultricies sed nulla. Faucibus sed est sed sit
                      suspendisse. Lorem ac amet.
                    </div>
                  </div>
                </div>
              </slot>
            </div>

            <div class="modal-footer-2">
              <slot name="footer">
                <div class="buttons">
                  <button @click="goToPrevPage()" class="primary outline dark">
                    Back
                  </button>
                  <button @click="goToNextPage()" class="primary">
                    Next
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import CustomSelect from "@/components/Select.vue";
export default {
  name: "OnboardingModal",
  components: {
    ModalCloseIcon,
    CustomSelect
  },
  data() {
    return {
      page: 0,
      pages: 5
    };
  },
  computed: {
    progressPercent() {
      return (this.page / this.pages) * 100;
    }
  },
  methods: {
    goToPage(page) {
      this.page = page;
    },
    goToPrevPage() {
      if (this.page === 0) return;
      this.page--;
    },
    goToNextPage() {
      if (this.page === this.pages) return;
      this.page++;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container-2 {
  width: 766px;
  height: 705px;
  display: flex;
  flex-direction: column;
  background: #2a2a2a;
  border-radius: 40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header-2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 32px;
  .logo {
    width: 55px;
  }
  .progressbar {
    height: 10px;
    width: 100%;
    margin: 0 148px;
    margin-top: 51px;
    margin-bottom: 8px;
    background: $text;
    border-radius: 10px;
    .line {
      height: 10px;
      // width: 50%;
      background: $accent;
      border-radius: 10px;
    }
  }
}

.modal-body-2 {
  flex: 1;
  display: flex;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  .step-1 {
    width: 100%;
    padding: 0px 180px;
    .inputs {
      width: 100%;
      .form-group {
        margin-bottom: 24px;
        label {
          font-weight: 300;
          text-align: center;
        }
        input {
          &::placeholder {
            text-align: center;
          }
        }
      }
    }
  }
  .step-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 83px;
    h3 {
      color: $white;
      font-weight: 300;
    }
    .inputs {
      width: 100%;
      display: flex;
      margin-top: 32px;
    }
  }
  .step-3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 125px;
    h5 {
      color: $white;
      font-weight: 300;
      margin: 0;
      margin-bottom: 24px;
    }
    .radiobuttons {
      margin-bottom: 16px;
      flex-wrap: nowrap;
      .radio-group {
        background: $text-additional;
      }
    }
    .group {
      .inline {
        display: flex;
        align-items: center;
        // margin-top: -4px;
        .text {
          font-weight: 300;
          color: $white;
        }
        .form-group {
          width: 84px;
          margin: 0 16px;
        }
      }
    }
  }
  .step-4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 116px;
    h5 {
      color: $white;
      font-weight: 300;
      margin: 0;
      margin-bottom: 24px;
    }
    .radios {
      text-align: center;
      margin-left: 75px;
    }
  }
  .step-5 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 116px;
    h5 {
      color: $white;
      font-weight: 300;
      margin: 0;
      margin-bottom: 24px;
    }
    .textarea {
      color: $white;
      border: 1px solid $text;
    }
  }
}

.modal-footer-2 {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  button {
    width: 136px;
    height: 44px;
    margin: 0 16px;
    font-weight: 300;
  }
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px;
}

.modal-body {
  padding: 34px 109px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .logo {
    margin-bottom: 32px;
  }
  h2 {
    color: $white;
  }
  h5 {
    color: $gray-6;
    font-weight: 300;
  }
  button {
    width: 136px;
    height: 44px;
  }
}
</style>
