<template>
  <div class="accordion mb-3 text-white" :class="animclass">
    <div
      class="row title align-items-center mb-3"
      @click="$emit('title-click')"
    >
      <h3 class="col h3 font-weight-bold">{{ item.title }}</h3>
    </div>
    <div class="row content">
      <p class="col" v-html="item.content"></p>
    </div>
  </div>
</template>
<script>
export default {
  name: "AccordionItem",
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: () => 1
    },
    animclass: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    indexDisplay() {
      return `0${this.index + 1}`.slice(-2);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/bootstrap";
.accordion {
  .title {
    opacity: 0.7;
  }
  .content {
    opacity: 0;
    height: 0;
    font-size: 1.2em;
  }
}
</style>
