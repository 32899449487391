<template>
  <div class="w-100">
    <div class="row mx-2 accordion-wrapper w-100">
      <div ref="imageContainer" class="col-5 pl-5 images-container">
        <image-item
          v-for="(item, idx) of items"
          :key="idx"
          :index="idx"
          ref="imageItems"
          :src="require(`../../assets/accordion/${item.img}.png`)"
          :animclass="`img-${idx}`"
        />
      </div>
      <div class="col-5 offset-1 mt-0">
        <div class="accordion-container">
          <accordion-item
            v-for="(item, idx) of items"
            :key="idx"
            :index="idx"
            :item="item"
            :animclass="`accordion-item-${idx}`"
          />
        </div>
      </div>
    </div>
    <div class="mobile-accordion">
      <div v-for="(item, idx) of items" :key="idx" class="mobile-item my-3">
        <img :src="require(`../../assets/accordion/${item.img}.png`)" />
        <p class="h3 mt-3 title">
          {{ item.title }}
        </p>
        <p class="content" v-html="item.content"></p>
      </div>
    </div>
  </div>
</template>
<script>
import ScrollMagic from "scrollmagic";
import { Power2, TimelineMax } from "gsap";
import gsap from "gsap";
import AccordionItem from "./accordion-item.vue";
import ImageItem from "./image.vue";
// import "debug.addIndicators";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const scrollDuration = 2;
const titleDuration = window.innerHeight * 0.2;
const contentDuration = window.innerHeight * 0.7;

// const imgHeight = 455;

export default {
  name: "Accordion",
  components: {
    AccordionItem,
    ImageItem
  },
  data: () => ({
    prevIdx: 0,
    inNavigate: false,
    items: [
      {
        title: "Build your deal",
        content:
          "We’ll walk you through royalties, copyrights, publishing, and more to craft your personalized contracts.",
        img: "img-0"
      },
      {
        title: "Negotiate the details",
        content:
          "Both sides can review and adjust the finer details, resolving conflict before it happens.",
        img: "img-1"
      },
      {
        title: "Sign and secure payment",
        content:
          "Digitally sign and store your contract.<br /><br /><span style='padding: 0 4px; border-radius: 4px; text-transform: uppercase; font-size: 16px; background: #eb345d;'>Coming Soon</span> If funds are involved, they'll be held securely until the project is delivered.",
        img: "img-2"
      }
    ]
  }),
  mounted() {
    this.controller = new ScrollMagic.Controller();

    new ScrollMagic.Scene({
      triggerElement: ".images-container",
      offset: 400,
      duration:
        this.$refs.imageContainer.clientHeight -
        this.$refs.imageItems[this.items.length - 1].$el.clientHeight
    })
      .setPin(".accordion-container")
      .addTo(this.controller);

    this.items.forEach((item, idx) => {
      const triggerElement = `.img-${idx}`;
      new ScrollMagic.Scene({
        triggerElement,
        duration: this.$refs.imageItems[idx].$el.clientHeight / 2.2
      })
        .setTween(this.tween(idx))
        .addTo(this.controller);
      if (idx < this.items.length - 1) {
        new ScrollMagic.Scene({
          triggerElement: `.img-${idx + 1}`,
          duration: this.$refs.imageItems[idx + 1].$el.clientHeight / 2.2
        })
          .setTween(this.tweenReversed(idx))
          .addTo(this.controller);
      }
    });
  },
  methods: {
    tween(idx) {
      return new TimelineMax()
        .delay(contentDuration)
        .to(`.accordion-item-${idx} .content`, contentDuration, {
          ease: "none",
          height: 180
        })
        .to(`.accordion-item-${idx} .content`, contentDuration, {
          ease: "none",
          opacity: 1
        })
        .to(`.accordion-item-${idx} .title`, titleDuration, {
          ease: Power2.easeInOut,
          opacity: 1
        });
    },
    tweenReversed(idx) {
      return new TimelineMax()
        .to(`.accordion-item-${idx} .content`, contentDuration, {
          ease: "none",
          opacity: 0
        })
        .to(`.accordion-item-${idx} .content`, contentDuration, {
          ease: "none",
          height: 0
        })
        .to(`.accordion-item-${idx} .title`, titleDuration, {
          ease: Power2.easeInOut,
          opacity: 0.7
        });
    },
    navigate(idx) {
      if (this.inNavigate) {
        return;
      }
      this.inNavigate = true;
      const onWheel = window.onwheel;
      window.onwheel = null;
      this.scroll(idx);
      gsap.to(window, scrollDuration, {
        scrollTo: {
          y: `.img-${idx}`,
          autoKill: false
        },
        ease: Power2.easeInOut,
        onComplete: () => {
          this.inNavigate = false;
          window.onwheel = onWheel;
        }
      });
    },
    scroll(idx) {
      const prevIdx = this.prevIdx;
      const nextIdx = idx;
      const notRunReverse = prevIdx === 0 && idx === 0;
      if (prevIdx > nextIdx) {
        this.tween(nextIdx)
          .delay(contentDuration)
          .play();
        !notRunReverse && this.tweenReversed(prevIdx).play();
      } else {
        this.tween(nextIdx)
          .delay(contentDuration)
          .play();
        !notRunReverse && this.tweenReversed(prevIdx).play();
      }
      this.prevIdx = idx;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/bootstrap";
.container {
  position: relative;
}
.accordion-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 600px;
}
.mobile-item {
  img {
    max-width: 100vw;
  }
}
.mobile-accordion {
  display: none;
}
@media (max-width: 838px) {
  .accordion-wrapper {
    display: none;
  }
  .mobile-accordion {
    display: flex;
    flex-direction: column;
    color: #ffffff;
  }
  .mobile-item {
    img {
      display: none;
    }
    .title {
      font-size: 2.2rem;
      line-height: 2.9rem;
    }
    .content {
      margin-top: 0.7rem;
      font-size: 1.3rem;
      line-height: 1.9rem;
    }
  }
}
</style>
