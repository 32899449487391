<template>
  <div>
    <center><img class="mx-2" height="50px" src="@/assets/logo.png" /></center>
    <footer class="home-footer">
      <div class="content">
        <div class="left">
          <p class="copy mb-0">
            &copy; 2023. All rights reserved. Nvoko, LLC.
          </p>
        </div>
        <div class="middle">
          <a href="/terms-of-use" class="link">Terms of Use</a>
          <a
            href="https://app.termly.io/document/privacy-policy/edbf75fb-d7c1-4c85-81b7-8f9a585e29d5"
            class="link"
            >Privacy Policy</a
          >
          <a
            class="link termly-cookie-preference-button"
            @click="displayPreferenceModal()"
            >Manage Cookie Preferences / Do Not Sell</a
          >
        </div>
        <div class="right">
          <div class="d-flex footer-icons">
            <!--<img class="mx-2" src="../assets/icons/pink-twitter.svg" />-->
            <a href="https://facebook.com/nvoko"
              ><img class="mx-2" src="../assets/icons/pink-facebook.svg"
            /></a>
            <a href="https://instagram.com/nvokoapp"
              ><img class="mx-2" src="../assets/icons/pink-instagram.svg"
            /></a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    displayPreferenceModal() {
      window.displayPreferenceModal();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "node_modules/bootstrap/scss/bootstrap";
.home-footer {
  display: flex;
  justify-content: center;
  background-color: #0a0a0a;
  .content {
    width: 1110px;
    padding: 32px 0;
    display: flex;
    .left {
      .copy {
        color: $white;
      }
    }
    .footer-icons {
      img {
        box-shadow: 0 21px 28px 0 rgba($accent, 0.3);
      }
    }
    .middle {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      .link {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $white;
        flex: none;
        order: 0;
        align-self: center;
        margin-left: 40px;
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      justify-content: center;
    }
  }
}
@media (max-width: 838px) {
  footer {
    .content {
      width: 100%;
      flex-direction: column;
      align-items: center;
      .middle {
        flex-direction: column;
        align-items: center;
        .link {
          margin: 8px;
        }
      }
    }
  }
}
</style>
