var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{class:{ active: _vm.isBurgerActive },attrs:{"id":"burger"},on:{"click":function($event){$event.preventDefault();return _vm.toggleNav($event)}}},[_vm._t("default",[_vm._m(0),_vm._m(1)])],2),_c('div',{staticClass:"sidebar"},[(_vm.isPanelOpen)?_c('div',{staticClass:"sidebar-backdrop",on:{"click":_vm.toggleNav}}):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(_vm.isPanelOpen)?_c('div',{staticClass:"sidebar-panel"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":"Nvoko Logo"}})]),_c('ul',{staticClass:"sidebar-panel-nav",staticStyle:{"display":"none"}},[_c('li',[_c('a',{staticClass:"link",attrs:{"href":"#pricing"}},[_vm._v("Pricing")])]),_c('li',{staticClass:"mt-3"},[_c('a',{staticClass:"link dark",on:{"click":function($event){_vm.showLoginModal = true}}},[_vm._v(" Log In ")])])]),_c('router-link',{attrs:{"to":{ name: 'Signup' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{staticClass:"primary outline dark",on:{"click":navigate}},[_vm._v(" Join Nvoko ")])]}}],null,false,3192627999)})],1):_vm._e()])],1),_c('section',{staticClass:"top"},[_c('div',{staticClass:"content"},[_vm._m(2),_c('nav',{staticClass:"menu"},[_vm._m(3),_c('div',{staticClass:"middle"}),_c('div',{staticClass:"right"},[_c('a',{staticClass:"link dark",on:{"click":function($event){_vm.showLoginModal = true}}},[_vm._v(" Log In ")]),_c('router-link',{attrs:{"to":{ name: 'Signup' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{staticClass:"primary outline dark",on:{"click":navigate}},[_vm._v(" Join Nvoko ")])]}}])})],1)]),_c('div',{staticClass:"hero-top"},[_c('h1',[_vm._v(" Where music deals happen ")]),_vm._m(4),_c('div',[_c('form',{staticClass:"optin",attrs:{"id":"optin"}},[_c('router-link',{attrs:{"to":{ name: 'Signup' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{staticClass:"primary join",on:{"click":navigate}},[_vm._v(" Get a free contract ")])]}}])})],1)])])])]),_vm._m(5),_c('section',{staticClass:"middle"},[_c('div',{staticClass:"content"},[_c('accordion')],1)]),_c('section',{staticClass:"reviews"},[_c('div',{staticClass:"content bridge text-center mt-5"},[_c('p',{staticClass:"h1 px-3"},[_vm._v(" Trusted by music veterans ")]),_c('div',{staticClass:"slider mt-5 pb-5"},[_c('div',{staticClass:"review"},[_c('singer-card',{attrs:{"item":_vm.singeritem1}})],1),_c('div',{staticClass:"review"},[_c('singer-card',{attrs:{"item":_vm.singeritem2}})],1),_c('div',{staticClass:"review"},[_c('singer-card',{attrs:{"item":_vm.singeritem3}})],1)])])]),_c('section',{staticClass:"plans"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 col-sm-12 mb-4 offset-md-2"},[_c('div',{staticClass:"plan-card pro"},[_c('h2',[_vm._v("Start securing your creative rights")]),_vm._m(6),_c('router-link',{attrs:{"to":{ name: 'Signup' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('button',{on:{"click":navigate}},[_vm._v(" Get a free contract ")])]}}])})],1)])])])]),_c('Footer'),(_vm.showLoginModal)?_c('LoginModal',{on:{"close":function($event){_vm.showLoginModal = false}}}):_vm._e(),(_vm.showSignupModal)?_c('SignupModal',{on:{"close":function($event){_vm.showSignupModal = false}}}):_vm._e(),(_vm.showOnboardingModal)?_c('OnboardingModal',{on:{"close":function($event){_vm.showOnboardingModal = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":"Nvoko Logo"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"burger-button",attrs:{"type":"button","title":"Menu"}},[_c('span',{staticClass:"hidden"},[_vm._v("Toggle menu")]),_c('span',{staticClass:"burger-bar burger-bar--1"}),_c('span',{staticClass:"burger-bar burger-bar--2"}),_c('span',{staticClass:"burger-bar burger-bar--3"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"temp-logo"},[_c('img',{attrs:{"src":require("../assets/logo.svg"),"alt":"Nvoko Logo"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.svg"),"alt":"Nvoko Logo"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',[_vm._v(" Create powerful and personalized music contracts."),_c('br',{staticClass:"hide-mobile"}),_vm._v(" Secure your creative rights, project fees, and royalties. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"bridge text-center mt-5"},[_c('p',{staticClass:"h1 px-3"},[_vm._v(" We manage the lifecycle"),_c('br'),_vm._v("of music collaboration ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"description px-3"},[_c('span',[_vm._v(" Unlimited contracts covering "),_c('strong',[_vm._v("buy-out")]),_vm._v(", "),_c('strong',[_vm._v("record royalties")]),_vm._v(", and "),_c('strong',[_vm._v("songwriter splits")]),_vm._v(" — all for "),_c('strong',[_vm._v("$49 per song")]),_vm._v(". ")])])}]

export { render, staticRenderFns }