const state = {
  apolloToken: null,
  user: null,
  artist: null
};

const actions = {};

const mutations = {
  authSuccess(state, payload) {
    const { token, user, artist } = payload;
    state.user = user;
    state.apolloToken = token;
    state.artist = artist || null;
  },
  updateUser(state, payload) {
    state.user = { ...(state.user || {}), ...payload };
  },
  updateArtist(state, payload) {
    state.artist = { ...(state.artist || {}), ...payload };
  },
  updateUserStatus(state, payload) {
    state.user.status = payload;
  },
  onboardingCompleted(state) {
    state.user.onboarding_complete = true;
  },
  logout(state) {
    state.apolloToken = null;
    state.user = null;
    state.artist = null;
  }
};

const getters = {
  getApolloToken(state) {
    return state.apolloToken;
  },
  getUser(state) {
    return state.user;
  },
  getArtist(state) {
    return state.artist;
  },
  getCompany(state) {
    return state.artist ? state.artist.artist_users[0].company : null;
  },
  getUserId(state) {
    return state.user.id;
  },
  getArtistId(state) {
    return state.artist.id;
  },
  isVerified(state) {
    const user = state.user || {};
    return user.status === "verified";
  },
  isOnboardingCompleted(state) {
    const user = state.user || {};
    return user.onboarding_complete;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
