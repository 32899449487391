<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="!submitting ? $emit('close') : 0" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <span class="title">CREATE A NEW DEAL</span>
              <div class="inputs">
                <div class="form-group">
                  <input
                    v-model="song.name"
                    id="song"
                    type="text"
                    class="text-input"
                    placeholder="Song Name / Track Title"
                  />
                </div>
              </div>
              <div class="search-title" v-if="resultsAvailable">
                <b>Search Results </b>
                <i>(Showing {{ results.length }} of {{ total }})</i>
              </div>
              <div
                class="results-container"
                v-if="!fieldsEmpty && !searchDisabled"
              >
                <div class="results" v-if="resultsAvailable">
                  <div
                    class="result"
                    v-for="(data, i) in results"
                    :key="i"
                    @click="selectResult(data)"
                  >
                    <div class="details">
                      <span>{{ data.name }}</span>
                    </div>
                  </div>
                </div>
                <div class="searching" v-if="searching">
                  Searching...
                </div>
                <div class="fetch-more" v-if="canFetchMore" @click="loadMore">
                  + Load More Results
                </div>
                <div class="no-results" v-if="total === 0">
                  No Search Results found
                </div>
              </div>
              <div class="buttons">
                <button
                  class="primary outline dark"
                  :disabled="submitting"
                  @click="$emit('close')"
                >
                  Cancel
                </button>
                <button
                  class="primary"
                  @click="add"
                  v-if="!song.id"
                  :disabled="!song.name || submitting || searching"
                >
                  {{ submitting ? "Submitting..." : `Create` }}
                </button>
                <button
                  class="primary"
                  @click="add"
                  v-else
                  :disabled="!song.name || submitting || searching"
                >
                  {{
                    submitting
                      ? "Submitting..."
                      : `Add ${artist.name} to ${song.name}`
                  }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

import ADD_SONG from "@/api/mutations/ADD_SONG.gql";
import ADD_COLLABORATOR from "@/api/mutations/ADD_COLLABORATOR.gql";
import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import SEARCH_SONGS from "@/api/queries/SEARCH_SONGS.gql";

export default {
  name: "AddSong",
  components: {
    ModalCloseIcon
  },
  props: {
    myArtist: {
      type: Object,
      required: true
    },
    artist: {
      type: Object,
      required: false,
      default: () => ({})
    },
    addNewRequired: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      song: {},
      offset: 0,
      results: [],
      total: null,
      fetchingMore: false,
      selected: {},
      submitting: false,
      addedSong: null
    };
  },
  watch: {
    searchData(data) {
      this.results = data.results;
      this.total = data.total;
    },
    fields() {
      if (this.searchDisabled) return;
      if (this.song.id) delete this.song.id;
    }
  },
  computed: {
    valid() {
      const {
        song: { id, name },
        artist
      } = this;
      if (id && name) return true;
      if (name && this.addingNew && artist.id) return true;
      return false;
    },
    fields() {
      return JSON.stringify(this.song);
    },
    fieldsEmpty() {
      return !this.song.name;
    },
    searchDisabled() {
      if (this.addNewRequired) return true;
      const { fieldsEmpty, isSelectionActive, addingNew } = this;
      return fieldsEmpty || isSelectionActive || addingNew;
    },
    searching() {
      return this.$apollo.queries.searchData.loading;
    },
    resultsAvailable() {
      return (
        this.results &&
        this.results.length &&
        (!this.searching || this.fetchingMore) &&
        !this.fieldsEmpty &&
        !this.searchDisabled
      );
    },
    canFetchMore() {
      const { results, resultsAvailable, total, searching } = this;
      return resultsAvailable && results.length < total && !searching;
    },
    isSelectionActive() {
      return JSON.stringify(this.song) === this.selected;
    }
  },
  methods: {
    loadMore() {
      this.offset += 10;
      this.fetchingMore = true;
    },
    selectResult(data) {
      this.selected = JSON.stringify(data);
      this.song = data;
    },
    isMe(artist) {
      return artist.id === this.$store.getters["account/getArtistId"];
    },
    checkChargesEnabled(artist) {
      return !!((artist.artist_users || [])[0].user || {}).charges_enabled;
    },
    getViewerInfo(collaborators) {
      const { recording, publishing } = collaborators.find(
        el => el.isMe
      ).percentage;
      return {
        isHost: collaborators[0].isMe,
        myRPM: [recording, publishing].join("/")
      };
    },
    getHostPercentage(guests = []) {
      let resp = {
        recording: 100,
        publishing: 100
      };
      guests.forEach(guest => {
        Object.keys(guest.percentage).forEach(el => {
          if (resp[el]) resp[el] -= guest.percentage[el];
        });
      });
      return resp;
    },
    formatArtist(artist) {
      if (artist.name) return artist;
      let name = artist.artist_users[0].user.email;
      return { ...artist, name };
    },
    formatSongs(songs) {
      return songs.map(({ id, name, splits, host: hostArtist }) => {
        let host = {
          info: this.formatArtist(hostArtist),
          isHost: true,
          isMe: this.isMe(hostArtist)
        };

        const details = { id, name };
        const { split_details = [] } = splits[0] || {};

        const guests = split_details.flatMap(el => [
          {
            status: el.deal.status,
            info: this.formatArtist(el.deal.artistTo),
            services: el.deal.services || [],
            fee_status: el.deal.fee_status,
            fee: el.deal.fee,
            percentage: el.percentage,
            deal_id: el.deal_id,
            isMe: this.isMe(el.deal.artistTo),
            chargesEnabled: this.checkChargesEnabled(el.deal.artistTo)
          }
        ]);

        host.percentage = this.getHostPercentage(guests);

        return {
          ...details,
          host_id: hostArtist.id,
          collaborators: [host, ...guests],
          ...this.getViewerInfo([host, ...guests])
        };
      });
    },
    formatSplit() {
      const { artist: collaborator } = this;
      const { id, collaborators, host_id } = this.addedSong || this.song;

      const proposer = collaborators.find(el => el.isMe).info.id;
      const split = {
        song_id: id,
        artist_id_proposer: proposer
      };

      const details = collaborators.flatMap(
        ({ info, deal_id, isHost, percentage }) => {
          if (isHost) return [];
          return [
            {
              deal_id,
              percentage,
              is_host: false,
              accepted: info.id === proposer
            }
          ];
        }
      );

      let newDetail = {
        accepted: false,
        deal: {
          data: {
            song_id: id,
            artist_id_from: host_id,
            artist_id_to: collaborator.id
          }
        },
        percentage: {
          recording: 0,
          publishing: 0,
          music_videos: 0
        }
      };

      return {
        ...split,
        split_details: { data: [...details, newDetail] }
      };
    },
    getConnections() {
      const {
        artist: { id }
      } = this;
      const { host_id } = this.addedSong || this.song;
      return !id ? [] : [{ artist_id_from: host_id, artist_id_to: id }];
    },
    addCollaborator() {
      const split = this.formatSplit();
      const connections = this.getConnections();
      return this.$apollo.mutate({
        mutation: ADD_COLLABORATOR,
        variables: { split, connections },
        update: (store, { data: { insert_splits_one } }) => {
          try {
            const data = store.readQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              }
            });

            const index = data.songs.findIndex(
              el => el.id === (this.song.id || this.addedSong.id)
            );
            data.songs[index] = {
              ...data.songs[index],
              splits: [insert_splits_one]
            };

            store.writeQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              },
              data
            });
          } catch (e) {
            // console.log(e);
          }
        }
      });
    },
    addSong() {
      return this.$apollo.mutate({
        mutation: ADD_SONG,
        variables: { name: this.song.name, host_id: this.myArtist.id },
        update: (store, { data: { insert_songs_one } }) => {
          try {
            const data = store.readQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              }
            });

            data.songs = [...data.songs, { ...insert_songs_one, splits: [] }];

            store.writeQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              },
              data
            });
          } catch (e) {
            // console.log(e);
          }
        }
      });
    },
    async add() {
      this.submitting = true;
      let song;
      if (!this.song.id) song = await this.addSong();
      this.addedSong = this.song.id
        ? this.song
        : await this.formatSongs([song.data.insert_songs_one])[0];
      if (this.artist.id) await this.addCollaborator();
      this.submitting = false;
      this.$router.push({
        name: "Songs",
        query: { song: this.song.id || song.data.insert_songs_one.id }
      });
      this.$emit("close");
    }
  },
  apollo: {
    searchData: {
      query: SEARCH_SONGS,
      variables() {
        const {
          song: { name },
          offset,
          myArtist
        } = this;

        const where = {
          _and: [
            { name: { _ilike: `%${name || ""}%` } },
            { host_id: { _eq: myArtist.id } }
          ]
        };

        return { offset, limit: 10, where };
      },
      update({
        songs_aggregate: {
          aggregate: { count }
        },
        songs
      }) {
        const results = this.fetchingMore
          ? [...this.results, ...this.formatSongs(songs)]
          : this.formatSongs(songs);
        this.fetchingMore = false;
        return { results, total: count };
      },
      skip() {
        return this.searchDisabled;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 600px;
  height: fit-content;
  margin: 0 5rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  padding: 0 34px 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .title {
    margin-bottom: 30px;
    font-size: 1.5rem;
    color: $white;
  }
  .inputs {
    min-width: 20rem;
    margin-bottom: 20px;
    .form-group {
      margin-bottom: 18px;
      input {
        @include details-light();
      }
    }
  }
  .switch-type {
    margin: 1rem 0;
    font-size: 0.9rem;
    color: $accent;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .search-title {
    font-size: 0.9rem;
    margin: 0.3rem;
    color: $text-additional;
    width: 80%;
  }
  .results-container {
    color: $white;
    width: 80%;
    max-height: 30vh;
    overflow-y: auto;
    padding-right: 0.5rem;
    margin-bottom: 1rem;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }
    .results {
      .result {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        background-color: $gray-1;
        padding: 0.5rem;
        border-radius: 7.5px;
        img {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          margin-right: 0.5rem;
          border: 1px $accent solid;
        }
        .initials {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 30px;
          font-family: sans-serif;
          font-size: 0.9rem;
          border-radius: 50%;
          background-color: $black;
          border: 1px solid $accent;
          color: $white;
          margin-right: 0.5rem;
        }
        .details {
          display: flex;
          flex-direction: column;
          span {
            font-size: 0.9rem;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: #3d3d3d;
        }
      }
    }
    .searching,
    .fetch-more,
    .no-results {
      margin-bottom: 0.5rem;
      background-color: $gray-1;
      padding: 0.7rem;
      border-radius: 5px;
      font-size: 0.8rem;
      text-align: center;
      &:hover {
        cursor: pointer;
        background-color: #3d3d3d;
      }
    }
  }
  .buttons {
    button {
      margin: 0 0.5rem;
      min-width: 136px;
      height: 44px;
    }
  }
}
</style>
