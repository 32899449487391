<template>
  <div class="home">
    <div
      id="burger"
      :class="{ active: isBurgerActive }"
      @click.prevent="toggleNav"
    >
      <slot>
        <div class="logo">
          <img src="../assets/logo.png" alt="Nvoko Logo" />
        </div>
        <button type="button" class="burger-button" title="Menu">
          <span class="hidden">Toggle menu</span>
          <span class="burger-bar burger-bar--1"></span>
          <span class="burger-bar burger-bar--2"></span>
          <span class="burger-bar burger-bar--3"></span>
        </button>
      </slot>
    </div>
    <div class="sidebar">
      <div class="sidebar-backdrop" @click="toggleNav" v-if="isPanelOpen"></div>
      <transition name="slide">
        <div v-if="isPanelOpen" class="sidebar-panel">
          <div class="logo">
            <img src="../assets/logo.png" alt="Nvoko Logo" />
          </div>
          <ul class="sidebar-panel-nav" style="display: none">
            <li>
              <a class="link" href="#pricing">Pricing</a>
            </li>
            <li class="mt-3">
              <a @click="showLoginModal = true" class="link dark">
                Log In
              </a>
            </li>
          </ul>
          <router-link :to="{ name: 'Signup' }" v-slot="{ navigate }">
            <button @click="navigate" class="primary outline dark">
              Join Nvoko
            </button>
          </router-link>
        </div>
      </transition>
    </div>
    <section class="top">
      <div class="content">
        <div class="temp-logo">
          <img src="../assets/logo.svg" alt="Nvoko Logo" />
        </div>
        <nav class="menu">
          <div class="logo">
            <img src="../assets/logo.svg" alt="Nvoko Logo" />
          </div>
          <div class="middle"></div>
          <div class="right">
            <a @click="showLoginModal = true" class="link dark">
              Log In
            </a>
            <router-link :to="{ name: 'Signup' }" v-slot="{ navigate }">
              <button @click="navigate" class="primary outline dark">
                Join Nvoko
              </button>
            </router-link>
          </div>
        </nav>
        <div class="hero-top">
          <h1>
            Where music deals happen
          </h1>
          <h2>
            Create powerful and personalized music contracts.<br class="hide-mobile" /> Secure your creative rights, project fees, and royalties.
          </h2>
          <div>
            <form id="optin" class="optin">
              <router-link :to="{ name: 'Signup' }" v-slot="{ navigate }">
                <button @click="navigate" class="primary join">
                  Get a free contract
                </button>
              </router-link>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section class="bridge text-center mt-5">
      <p class="h1 px-3">
        We manage the lifecycle<br />of music collaboration
      </p>
    </section>
    <section class="middle">
      <div class="content">
        <accordion />
      </div>
    </section>
    <section class="reviews">
      <div class="content bridge text-center mt-5">
        <p class="h1 px-3">
          Trusted by music veterans
        </p>
        <div class="slider mt-5 pb-5">
          <div class="review">
            <singer-card :item="singeritem1" />
          </div>
          <div class="review">
            <singer-card :item="singeritem2" />
          </div>
          <div class="review">
            <singer-card :item="singeritem3" />
          </div>
        </div>
      </div>
    </section>
    <section class="plans">
      <div class="content">
        <!--
        <p class="h1 font-weight-bold">
          Join the pros
        </p>
        <p class="h2">
          Start securing your creative rights
        </p>
        -->
        <div class="row">
          <div class="col-md-8 col-sm-12 mb-4 offset-md-2">
            <div class="plan-card pro">
              <h2>Start securing your creative rights</h2>
              <p class="description px-3">
                <span>
                  Unlimited contracts covering <strong>buy-out</strong>,
                  <strong>record royalties</strong>, and
                  <strong>songwriter splits</strong> &mdash; all for <strong>$49 per song</strong>.
                </span>
              </p>
              <router-link :to="{ name: 'Signup' }" v-slot="{ navigate }">
                <button @click="navigate">
                  Get a free contract
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <!-- use the modal component, pass in the prop -->
    <LoginModal
      v-if="showLoginModal"
      @close="showLoginModal = false"
    ></LoginModal>
    <SignupModal
      v-if="showSignupModal"
      @close="showSignupModal = false"
    ></SignupModal>
    <OnboardingModal
      v-if="showOnboardingModal"
      @close="showOnboardingModal = false"
    >
    </OnboardingModal>
  </div>
</template>

<script>
import LoginModal from "@/components/modals/Login.vue";
import SignupModal from "@/components/modals/Signup.vue";
import OnboardingModal from "@/components/modals/Onboarding.vue";
import Footer from "@/components/Footer.vue";
import Accordion from "@/components/accordion";
import SingerCard from "@/components/SingerCard";

export default {
  name: "Home",
  components: {
    Footer,
    LoginModal,
    SignupModal,
    OnboardingModal,
    SingerCard,
    Accordion
  },
  data() {
    return {
      isNavOpen: false,
      showLoginModal: false,
      showSignupModal: false,
      showOnboardingModal: false,
      selectedPlan: "monthly",
      singeritem2: {
        img: require("../assets/testimonials/joel.png"),
        title: "Joel Woods",
        subtitle: "Musician & Producer",
        description:
          "Collaboration is the foundation of my work. Nvoko captures each project's differences, and builds a unique contract every time."
      },
      singeritem1: {
        img: require("../assets/testimonials/lucidious.png"),
        title: "Lucidious",
        subtitle: "Musician, 200m+ streams",
        description:
          "Nvoko allows artists to get the protection they deserve and focus on what’s most important: the music."
      },
      singeritem3: {
        img: require("../assets/testimonials/ryan.png"),
        title: "Ryan Oakes",
        subtitle: "Musician, 120m+ streams",
        description:
          "Like many artists, I trusted a handshake. Nvoko would have saved me from losing 40% of my streaming income."
      }
    };
  },
  created: function() {
    const { state } = this.$route.query;
    if (state === "instagram") {
      this.showSignupModal = true;
    }
  },
  computed: {
    isBurgerActive() {
      return this.isNavOpen;
    },
    isPanelOpen() {
      return this.isNavOpen;
    }
  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../styles/_mixins.scss";
@import "node_modules/bootstrap/scss/bootstrap";
.home {
  background: linear-gradient(180deg, #0b0b0b 0%, #000000 100%);
}

#burger {
  display: none;
}
button.join {
  font-weight: 600;
  font-size: 19px;
  line-height: 16px;
  padding: 20px 30px 24px;
  min-width: 210px;
}
.optin {
  margin: 0 auto;
  input,
  button {
    margin-top: 5px;
  }
}
a.link {
  cursor: pointer;
}
.top {
  background-position-y: bottom;
  height: 600px;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  .content {
    width: 1110px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    .menu {
      display: flex;
      width: 100vw;
      position: fixed;
      top: 0;
      left: 0;
      padding-top: 22px;
      padding-left: calc(calc(100vw - 1110px) / 2);
      padding-right: calc(calc(100vw - 1110px) / 2);
      padding-bottom: 20px;
      background: #0b0b0b;
      // -webkit-backdrop-filter: blur(20px);
      // backdrop-filter: blur(20px);
      z-index: 10;
      .middle {
        flex: 1;
        display: flex;
        justify-content: center;
      }
      .link {
        /* Category 16px medium */
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.22px;
        color: $white;
        flex: none;
        order: 0;
        align-self: center;
        margin: 0 40px;
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          width: 136px;
          height: 44px;
          font-size: 16px;
          line-height: 16px;
          &:hover {
            background: $accent;
          }
        }
      }
    }
    h1 {
      color: $white;
      margin-top: 200px;
      font-size: 50px;
      font-weight: 600;
    }
    h2 {
      font-size: 24px;
      font-weight: normal;
      color: $white;
      line-height: 32px;
      margin-top: 24px;
      margin-bottom: 40px;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }
    .temp-logo {
      display: none;
    }
    .logo img {
      max-height: 40px;
    }
    .artist-name {
      margin-top: auto;
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      text-align: right;
      color: $white;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
    .artist-listeners {
      font-size: 16px;
      line-height: 28px;
      text-align: right;
      color: $white;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
.bridge {
  h1,
  .h1 {
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
    color: $white;
  }
}
.middle {
  display: flex;
  justify-content: center;
  .content {
    width: 1110px;
    display: flex;
    justify-content: space-between;
    .left {
      padding: 27px 0;
      .info {
        max-width: 445px;
        .step {
          display: flex;
          color: $white;
          margin-bottom: 40px;
          .number {
            font-size: 24px;
            line-height: 28px;
            margin-right: 37px;
          }
          .details {
            .title {
              font-weight: bold;
              font-size: 24px;
              line-height: 32px;
            }
            .text {
              margin-top: 16px;
              color: $white;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
        & .active {
          color: $white;
        }
      }
    }
  }
}
.hero-top {
  text-align: center;
}
.reviews {
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    h2 {
      text-align: center;
      margin-bottom: 40px;
    }
    .slider {
      display: flex;
      overflow-x: auto;
      .review {
        margin: 0 15px;
      }
    }
  }
}
.feedback {
  display: flex;
  justify-content: center;
  padding: 32px;
  background: #1a1a1a;
  .content {
    width: 1110px;
    display: flex;
    justify-content: space-between;
    .left {
      max-width: 445px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        color: $white;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
      }
      p {
        color: $white;
        margin-top: 24px;
        font-size: 18px;
        line-height: 28px;
      }
    }
    .right {
      margin-top: 80px;
    }
  }
}
.plans {
  display: flex;
  justify-content: center;
  padding: 80px 20px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)),
    url("../assets/top.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 65%;
  .content {
    width: 1110px;
    display: flex;
    flex-direction: column;
    text-align: center;
    h1 {
      color: $white;
    }
    p {
      color: $white;
      margin-top: 16px;
    }
    .plan-select {
      margin-top: 32px;
      .plan {
        color: $white;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        padding-bottom: 16px;
        margin: 0 40px;
      }
      & .active {
        border-bottom: 2px solid $accent;
      }
    }
    .plan-card {
      /*cursor: pointer;*/
      height: 100%;
      position: relative;
      padding: 40px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 25px;
      background: linear-gradient(
          210.04deg,
          rgba(255, 255, 255, 0.07) 12.89%,
          rgba(255, 255, 255, 0) 81.68%
        ),
        #000000;
      box-sizing: border-box;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 25px;
      border: 2px solid #ccc;
      display: flex;
      flex-direction: column;
      transition: 0.3s;

      strong {
        color: $accent;
      }

      .medium {
        font-size: 20px;
        margin-top: 10px;
      }

      /*&:hover,*/
      &.pro {
        border: 2px solid #ff035a;
      }

      &:hover {
        button {
          border: 2px solid #ffffff;
        }
      }

      h2 {
        color: $white;
        font-style: normal;
        font-weight: bold;
        font-size: 38px;
      }
      .price {
        color: $white;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 24px;
      }
      button {
        align-self: center;
        background-color: $accent;
        border-radius: 25px;
        color: #ffffff;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        transition: 0.3s;
        padding: 13px 36px 17px;
        border-color: transparent;
        outline: none;
        max-width: 232px;
        margin-top: 14px;
      }
      .plan-extra {
        position: absolute;
        bottom: -70px;
        left: 0;
        width: 100%;
        text-align: center;
      }
      .description {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 40px;
      }
    }
  }
}
@media (max-width: 838px) {
  .hide-mobile {
    display: none;
  }
  .top {
    height: auto;
    background-position-x: -760px;
    .artist-name,
    .artist-listeners {
      display: none;
    }
    .content {
      .temp-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-height: 30px;
        }
      }
      nav.menu {
        display: none;
      }
      .hero-top {
        h1 {
          margin-top: 30px;
          font-size: 2.3rem;
          line-height: 3rem;
        }
        button {
          min-width: 230px;
          margin-top: 15px;
        }
      }
    }
  }
  .bridge {
    h1,
    .h1 {
      margin-top: 30px;
      font-size: 2.3rem;
      line-height: 3.3rem;
    }
  }
  .middle {
    .content {
      flex-direction: column;
      align-items: center;
      padding: 32px;
    }
  }
  .reviews {
    .content {
      .slider {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .review {
          margin: 10px 0;
        }
      }
    }
  }
  .feedback {
    .content {
      flex-direction: column;
      align-items: center;
    }
  }
  .plans {
    padding-top: 80px;
    .content {
      padding: 10px;
      .plan-card {
        padding: 40px 0;
        button {
          width: auto;
          height: 44px;
          line-height: 16px;
        }
        .plan-extra {
          bottom: -120px;
        }
      }
    }
  }

  .main-nav {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.8rem;
  }

  ul.sidebar-panel-nav {
    list-style-type: none;
  }

  ul.sidebar-panel-nav > li > a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    display: block;
    padding-bottom: 0.5em;
  }

  /* burger */

  #burger {
    position: fixed;
    top: 0;
    display: flex;
    display: none;
    justify-content: space-between;
    background-color: #0b0b0b;
    padding: 8px;
    width: 100vw;
    z-index: 1;
    .logo {
      width: 32px;
      height: 32px;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    }
  }

  .hidden {
    visibility: hidden;
  }

  button {
    cursor: pointer;
    width: 32px;
  }

  /* remove blue outline */
  button:focus {
    outline: 0;
  }

  .burger-button {
    position: relative;
    height: 30px;
    width: 32px;
    display: block;
    z-index: 999;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    pointer-events: all;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .burger-bar {
    background-color: $white;
    position: absolute;
    top: 50%;
    right: 6px;
    left: 6px;
    height: 2px;
    width: auto;
    margin-top: -1px;
    transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
      background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  .burger-bar--1 {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }

  .burger-bar--2 {
    transform-origin: 100% 50%;
    transform: scaleX(0.8);
  }

  .burger-button:hover .burger-bar--2 {
    transform: scaleX(1);
  }

  .no-touchevents .burger-bar--2:hover {
    transform: scaleX(1);
  }

  .burger-bar--3 {
    transform: translateY(6px);
  }

  #burger.active .burger-button {
    transform: rotate(-180deg);
  }

  #burger.active .burger-bar {
    background-color: #fff;
  }

  #burger.active .burger-bar--1 {
    transform: rotate(45deg);
  }

  #burger.active .burger-bar--2 {
    opacity: 0;
  }

  #burger.active .burger-bar--3 {
    transform: rotate(-45deg);
  }

  /** sidebar **/

  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.2s ease;
  }

  .slide-enter,
  .slide-leave-to {
    transform: translateX(-100%);
    transition: all 150ms ease-in 0s;
  }

  .sidebar-backdrop {
    background-color: rgba(19, 15, 64, 0.4);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  .sidebar-panel {
    overflow-y: auto;
    background-color: #2a2a2a;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    padding: 24px;
    width: 300px;
    .logo {
      width: 64px;
      height: 64px;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    }
    ul {
      margin: 24px 0;
    }
    button {
      width: 136px;
      height: 44px;
      line-height: 16px;
    }
  }
}
</style>
