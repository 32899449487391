<template>
  <div
    class="custom-select"
    :class="{ 'select-m': !noMargin }"
    tabindex="0"
    @blur="open = false"
  >
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected.label }} <i class="arrow-down" />
    </div>
    <div class="options" v-show="open">
      <div v-for="(option, i) of options" :key="i" @click="select(option)">
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [{}]
    },
    default: String,
    noMargin: Boolean,
    setValue: Function,
    val: [String, Boolean]
  },
  data() {
    return {
      selected: {
        label: this.default
      },
      open: false
    };
  },
  mounted() {
    if (![null, undefined].includes(this.val)) {
      this.selected = this.options.find(el => el.value === this.val);
      this.$emit("input", this.selected.value);
    }
  },
  watch: {
    val(o) {
      this.selected = this.options.find(el => el.value === o);
    }
  },
  methods: {
    select(option) {
      this.selected = option;
      this.open = false;
      this.$emit("setValue", option.value);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.custom-select {
  outline: none;
  cursor: pointer;
  position: relative;
  min-width: 100px;
  font-size: 14px;
  letter-spacing: -0.22px;

  .selected {
    border-radius: 25px;
    background-color: $gray;
    color: #3b3b3b;
    padding: 0 16px;
    letter-spacing: -0.22px;
    border: none;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    border: 1px #e9e9e9 solid;

    .arrow-down {
      content: "";
      margin: 5px 0 0 7px;
      border: 5px solid transparent;
      border-color: #3b3b3b transparent transparent transparent;
    }
  }

  .open {
    border-radius: 20px 20px 0 0;
  }

  .options {
    position: absolute;
    background-color: $gray;
    color: #3b3b3b;
    border-radius: 0 0 20px 20px;
    border: 1px #e9e9e9 solid;
    overflow: hidden;
    margin-top: 3px;
    width: 100%;
    text-align: center;
    z-index: 1;

    div {
      padding: 5px 0;
      border-bottom: 1px #ebebeb solid;
      &:hover {
        background-color: #e6e3e3;
      }
    }
  }
}
.select-m {
  margin-left: 32px;
}

.dark {
  .selected {
    border-color: $text-additional;
    background-color: $text-additional;
    color: $white;
  }
  .options {
    background-color: $text-additional;
    color: $white;
    border-color: #484848;
    div {
      border-bottom-color: #484848;
      &:hover {
        background-color: #696969;
      }
    }
  }
}

.left {
  .selected {
    justify-content: center;
    padding: 0 22px;
    .arrow-down {
      margin-left: auto;
    }
  }
}
</style>
