import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import TermsOfUse from "../views/TermsOfUse.vue";
// import Artists from "../views/Artists.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Home" }
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/Signup"),
    meta: { title: "Sign up" }
  },
  {
    path: "/signup/verification",
    name: "SignupVerification",
    component: () => import("../views/SignupVerification"),
    meta: { title: "Verification" }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword"),
    meta: { title: "Reset Password" }
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: () => import("../views/Onboarding"),
    meta: { title: "Onboarding" }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: { title: "About" }
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () =>
      import(/* webpackChunkName: "artists" */ "../views/Contacts.vue"),
    meta: { title: "Contacts" }
  },
  {
    path: "/deals",
    name: "Deals",
    component: () =>
      import(/* webpackChunkName: "offersent" */ "../views/Deals.vue")
  },
  {
    path: "/songs",
    name: "Songs",
    component: () =>
      import(/* webpackChunkName: "offersent" */ "../views/Catalog.vue"),
    meta: { title: "Songs" }
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/Feedback.vue")
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: () =>
      import(/* webpackChunkName: "wallet" */ "../views/Wallet.vue"),
    meta: { title: "Wallet" }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Settings.vue"),
    meta: { title: "Settings" }
  },
  {
    path: "/settings/artist/team",
    name: "TeamManagement",
    component: () =>
      import(/* webpackChunkName: "team" */ "../views/TeamManagement.vue")
  },
  {
    path: "/settings/user/subscription",
    name: "Subscription",
    component: () =>
      import(/* webpackChunkName: "team" */ "../views/Subscription.vue"),
    meta: { title: "Subscription" }
  },
  {
    path: "/settings/user/payouts",
    name: "Payouts",
    component: () =>
      import(/* webpackChunkName: "team" */ "../views/Payouts.vue"),
    meta: { title: "Payouts" }
  },
  {
    path: "/settings/user/social",
    name: "SocialAccounts",
    component: () =>
      import(/* webpackChunkName: "social" */ "../views/SocialAccounts.vue")
  },
  {
    path: "/settings/user/payments",
    name: "Payments",
    component: () =>
      import(/* webpackChunkName: "payments" */ "../views/Payments.vue")
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: TermsOfUse,
    meta: { title: "Terms of Use" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/",
    "/home",
    "/signup",
    "/reset-password",
    "/terms-of-use"
  ];

  const loggedIn = store.getters["account/getApolloToken"];
  const authRequired = !publicPages.includes(to.path);

  const verified = store.getters["account/isVerified"];
  const verificationRequired = ![
    ...publicPages,
    "/signup/verification"
  ].includes(to.path);

  const onboardingCompleted = store.getters["account/isOnboardingCompleted"];
  const onboardingRequired = ![
    ...publicPages,
    "/signup/verification",
    "/onboarding"
  ].includes(to.path);

  if (authRequired && !loggedIn) {
    next({ name: "Home" });
  } else if (verificationRequired && !verified) {
    next({ name: "SignupVerification" });
  } else if (onboardingRequired && !onboardingCompleted) {
    next({ name: "Onboarding" });
  } else {
    next();
  }
});

router.afterEach(to => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title =
      typeof to.meta.title !== "undefined"
        ? to.meta.title + " | Nvoko"
        : "Nvoko";
  });
});

export default router;
