<template>
  <div class="card-singer">
    <div class="header">
      <div class="left">
        <div class="image">
          <img :src="item.img" height="80px" />
        </div>
      </div>
      <div class="right">
        <p class="title">{{ item.title }}</p>
        <p class="subtitle">{{ item.subtitle }}</p>
      </div>
    </div>
    <div class="body">
      <p class="text">
        {{ item.description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "SingerCard",
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/bootstrap";
.card-singer {
  width: 350px;
  height: 294px;
  background: linear-gradient(
      210.04deg,
      rgba(255, 255, 255, 0.07) 12.89%,
      rgba(255, 255, 255, 0) 81.68%
    ),
    #000000;
  mix-blend-mode: normal;
  border: 1px solid #2e2e2e;
  box-sizing: border-box;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.55);
  border-radius: 20px;
  padding: 24px;

  .header {
    display: flex;
    .left {
      .image {
        img {
          border-radius: 50%;
          border: 1px solid rgba(255, 255, 255, 0.5);
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 16px;
      .title {
        color: $white;
        font-weight: bold;
        font-size: 22px;
        line-height: 24px;
      }
      .subtitle {
        color: $white;
        font-size: 16px;
        line-height: 16px;
      }
    }
  }
  .body {
    .text {
      margin-top: 24px;
      color: $white;
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>
