import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/main.scss";

import VueApollo from "vue-apollo";

import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";

import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

Vue.use(VueApollo);

Vue.config.productionTip = false;

let token = store.getters["account/getApolloToken"];

Object.defineProperty(Vue.prototype, "$token", {
  get() {
    return token;
  },
  set(value) {
    token = value;
  }
});

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_HTTPS_API
});

const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_WSS_API,
  options: {
    reconnect: true,
    connectionParams: () => ({
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    };
  } else {
    return headers;
  }
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: {
        __schema: {
          types: []
        }
      }
    })
  })
});

const apolloProvider = new VueApollo({
  defaultClient: client
});

Vue.mixin({
  methods: {
    resetSocketConnection: () => {
      wsLink.subscriptionClient.close();
    }
  }
});

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount("#app");
