<template>
  <div class="img" :class="animclass">
    <img :src="src || `https://loremflickr.com/320/320/a?random=${index}`" style="border-radius: 12px" />
  </div>
</template>
<script>
export default {
  name: "ImageContainer",
  props: {
    index: {
      type: Number,
      default: () => 1
    },
    src: {
      type: String,
      default: () => ""
    },
    animclass: {
      type: String,
      default: () => ""
    }
  }
};
</script>
<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/bootstrap";
.img {
  height: 80vh;
  display: flex;
  align-items: center;
  &:first-child,
  &:last-child {
    height: 600px;
  }
  img {
    width: 100%;
  }
}
</style>
