var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"sidebar"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"artist"},[(_vm.artist.avatar_url)?_c('img',{attrs:{"src":_vm.artist.avatar_url,"alt":""}}):_c('div',{staticClass:"initials"},[_vm._v(_vm._s(_vm._f("initials")(_vm.artist.name)))]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.artist.name))]),(_vm.isPro)?_c('span',{staticClass:"pro"},[_vm._v("PRO")]):_vm._e()]),_c('div',{staticClass:"menu"},[(_vm.isSettings)?[_c('div',{staticClass:"menu-item back",on:{"click":function($event){_vm.isSettings = false}}},[_vm._m(1),_c('div',{staticClass:"name"},[_vm._v(" Main Menu ")])]),_c('div',{staticClass:"section-title"},[_vm._v("Artist")]),_c('router-link',{attrs:{"to":{ name: 'ArtistProfile' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menu-item",class:[isActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Dashboard.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Settings ")])])])]}}],null,false,1550610700)}),_c('router-link',{attrs:{"to":{ name: 'TeamManagement' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"menu-item",class:[isExactActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Feedback.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" My Team ")])])])]}}],null,false,747782413)}),_c('div',{staticClass:"section-title"},[_vm._v("User")]),_c('router-link',{attrs:{"to":{ name: 'UserProfile' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menu-item",class:[isActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Dashboard.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Settings ")])])])]}}],null,false,1550610700)}),_c('router-link',{attrs:{"to":{ name: 'Subscription' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"menu-item",class:[isExactActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Feedback.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Subscription ")])])])]}}],null,false,1103169799)}),_c('router-link',{attrs:{"to":{ name: 'Payouts' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"menu-item",class:[isExactActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Feedback.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Payouts ")])])])]}}],null,false,2539991409)}),_c('div',{staticClass:"menu-item",on:{"click":_vm.logout}},[_vm._m(2),_c('div',{staticClass:"name"},[_vm._v(" Log out ")])])]:[_c('div',{staticClass:"menu-item create-deal",on:{"click":function($event){_vm.showModal = 'add-song'}}},[_vm._m(3),_c('div',{staticClass:"name"},[_vm._v(" Create Deal ")])]),_c('router-link',{attrs:{"to":{ name: 'Songs' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menu-item",class:[isActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Catalog.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Songs ")])])])]}}])}),(false)?_c('router-link',{attrs:{"to":{ name: 'Wallet' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"menu-item",class:[isExactActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Wallet.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Wallet ")])])])]}}],null,false,4187810253)}):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'Contacts' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menu-item",class:[isActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/User.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Collaborators ")])])])]}}])}),_c('router-link',{attrs:{"to":{ name: 'Settings' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('div',{staticClass:"menu-item",class:[isActive && 'active'],on:{"click":navigate}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Dashboard.svg")}})]),_c('div',{staticClass:"name"},[_c('a',{attrs:{"href":href}},[_vm._v(" Settings ")])])])]}}])}),_c('div',{staticClass:"menu-item",on:{"click":function($event){_vm.showModal = 'switch-artist'}}},[_vm._m(4),_c('div',{staticClass:"name"},[_vm._v(" Switch Artist ")])])]],2),_vm._m(5)]),(_vm.showModal === 'switch-artist')?_c('SwitchArtist',{on:{"close":function($event){_vm.showModal = ''}}}):_vm._e(),(_vm.showModal === 'add-song')?_c('AddSong',{attrs:{"my-artist":_vm.artist,"add-new-required":""},on:{"close":function($event){_vm.showModal = ''}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/logo.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon flip"},[_c('img',{attrs:{"src":require("../assets/icons/Arrow-right-alt.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Logout.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/CreateDeal.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/icons/Switch.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom"},[_c('img',{staticClass:"n-logo",attrs:{"src":require("../assets/logo.png")}}),_vm._v(" © 2023. "),_c('a',{staticClass:"link",attrs:{"href":"/terms-of-use"}},[_vm._v("Terms.")]),_c('a',{staticClass:"link",attrs:{"href":"https://app.termly.io/document/privacy-policy/edbf75fb-d7c1-4c85-81b7-8f9a585e29d5"}},[_vm._v("Privacy.")])])}]

export { render, staticRenderFns }